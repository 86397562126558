<template>
  <div id="main-wrapper">
    <!-- <Container/> -->
    <div class="position-relative">
      <section class="blockElement">
        <div class="container privacyPolicy">
          <!-- Global Start -->
          <h1 class="mb-4" v-if="entityName == 'global'">{{contentlist.merchant_agreement_global_content1}}</h1>
          <!-- Global End -->

          <!-- APEC Start -->
          <h1 class="mb-4" v-else>{{contentlist.merchant_agreement_apec_content1}}</h1>
          <!-- APEC End -->

          <p>{{contentlist.merchant_agreement_content2}}

            <!-- Global Start -->
            <router-link :to="entityPath+'/'" class="secondarycolor px-1"  v-if="entityName == 'global'">{{contentlist.agreement_global_content3}}</router-link>
            <!-- Global End -->

            <!-- APEC Start -->
            <router-link :to="entityPath+'/'" class="secondarycolor px-1" v-else>{{contentlist.agreement_apec_content3}}</router-link>
            <!-- APEC End -->

            <!-- Global Start -->
            <span  v-if="entityName == 'global'">{{contentlist.agreement_global_content4}}</span> 
            <!-- Global End -->

            <!-- APEC Start -->
            <span v-else>{{contentlist.agreement_apec_content4}}</span>
            <!-- APEC End -->
          
          </p>
          <p>{{contentlist.agreement_content5}}</p>
          <p>{{contentlist.agreement_content6}}</p>
          <h5>{{contentlist.agreement_content7}}</h5>
          <p>{{contentlist.agreement_content8}}</p>
          <p>{{contentlist.agreement_content9}}</p>
          <p>
            <strong>{{contentlist.agreement_content10}}</strong>
          </p>
          <p>{{contentlist.agreement_content11}} <router-link :to="entityPath+'/supported-coin'" class="secondarycolor">{{contentlist.agreement_content12}}</router-link> {{contentlist.agreement_content13}}</p>
          <p>{{contentlist.agreement_content14}} <router-link :to="entityPath+'/merchant-solution'" class="secondarycolor">{{contentlist.agreement_content15}}</router-link> {{contentlist.agreement_content16}}</p>
          <p>
            <strong>{{contentlist.agreement_content17}}</strong>
          </p>
          <p>{{contentlist.agreement_content18}}</p>
          <p>
            <strong>{{contentlist.agreement_content19}}</strong>
          </p>
          <p>{{contentlist.agreement_content20}}</p>
          <p>
            <strong>{{contentlist.agreement_content21}}</strong>
          </p>
          <p>{{contentlist.agreement_content22}}</p>
          <p>
            <strong>{{contentlist.agreement_content23}}</strong>
          </p>
          <p>{{contentlist.agreement_content24}} <router-link :to="entityPath+'/contact-us'" class="secondarycolor">{{contentlist.agreement_content25}}</router-link> {{contentlist.agreement_content26}}</p>
          <p>
            <strong>{{contentlist.agreement_content27}}</strong>
          </p>
          <p>{{contentlist.agreement_content28}}</p>
          <p>
            <strong>{{contentlist.agreement_content29}}</strong>
          </p>
          <p>{{contentlist.agreement_content30}}</p>
          <p>
            <strong>{{contentlist.agreement_content31}}</strong>
          </p>
          <p>
            <strong>{{contentlist.agreement_content32}}</strong> {{contentlist.agreement_content33}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content34}}</strong> {{contentlist.agreement_content35}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content36}}</strong> {{contentlist.agreement_content37}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content38}}</strong> {{contentlist.agreement_content39}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content40}}</strong> {{contentlist.agreement_content41}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content42}}</strong> {{contentlist.agreement_content43}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content44}}</strong>
          </p>
          <p>{{contentlist.agreement_content45}} <a href="https://apidocs.capitalwallet.com/" class="secondarycolor">{{contentlist.agreement_content46}}</a> {{contentlist.agreement_content47}}</p>
          <h5>{{contentlist.agreement_content48}}</h5>
          <p>
            <strong>{{contentlist.agreement_content49}}</strong> {{contentlist.agreement_content50}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content51}}</strong> {{contentlist.agreement_content52}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content53}}</strong> {{contentlist.agreement_content54}}
          </p>
          <h5>{{contentlist.agreement_content55}}</h5>
          <p>
            <strong>{{contentlist.agreement_content56}}</strong> {{contentlist.agreement_content57}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content58}}</strong> {{contentlist.agreement_content59}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content60}}</strong> {{contentlist.agreement_content61}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content62}}</strong> {{contentlist.agreement_content63}}
          </p>
          <h5>{{contentlist.agreement_content64}}</h5>
          <p>
            <strong>{{contentlist.agreement_content65}}</strong> {{contentlist.agreement_content66}} <router-link :to="entityPath+'/pricing'" class="secondarycolor">{{contentlist.agreement_content67}}</router-link> {{contentlist.agreement_content68}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content69}}</strong> {{contentlist.agreement_content70}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content71}}</strong> {{contentlist.agreement_content72}}
          </p>
          <h5>{{contentlist.agreement_content73}}</h5>
          <p>
            <strong>{{contentlist.agreement_content74}}</strong> {{contentlist.agreement_content75}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content76}}</strong> {{contentlist.agreement_content77}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content78}}</strong> {{contentlist.agreement_content79}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content80}}</strong> {{contentlist.agreement_content81}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content82}}</strong> {{contentlist.agreement_content83}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content84}}</strong> {{contentlist.agreement_content85}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content86}}</strong> {{contentlist.agreement_content87}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content88}}</strong> {{contentlist.agreement_content89}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content90}}</strong> {{contentlist.agreement_content91}}
          </p>
          <h5>{{contentlist.agreement_content92}}</h5>
          <p>
            <strong>{{contentlist.agreement_content93}}</strong> {{contentlist.agreement_content94}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content95}}</strong> {{contentlist.agreement_content96}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content97}}</strong> 
            
            <!-- Global Start -->
            <span class="d-inline pl-1"  v-if="entityName == 'global'">
              {{contentlist.agreement_global_content98}}
            </span>
            <!-- Global End -->

            <!-- APEC Start -->
            <span class="d-inline pl-1" v-else>
              {{contentlist.agreement_apec_content98}}
            </span>
            <!-- APEC End -->

          </p>
          <p>
            <strong>{{contentlist.agreement_content99}}</strong> 
            
            <!-- Global Start -->
            <span class="d-inline pl-1"  v-if="entityName == 'global'">
              {{contentlist.merchant_agreement_global_content100}}
            </span>
            <!-- Global End -->

            <!-- APEC Start -->
            <span class="d-inline pl-1" v-else>
              {{contentlist.merchant_agreement_apec_content100}}
            </span>
            <!-- APEC End -->
            
          </p>
          <p  v-if="entityName == 'global'">
            <strong>{{contentlist.agreement_content101}}</strong> 
            
            <!-- Global Start -->
              {{contentlist.agreement_global_content102}}
            <!-- Global End -->

          </p>
          <p  v-else>
            <strong>{{contentlist.agreement_content101}}</strong> 
            

            <!-- APEC Start -->
              {{contentlist.agreement_apec_content102}}
            <!-- APEC End -->

          </p>
          <p>
            <strong>{{contentlist.agreement_content103}}</strong> {{contentlist.agreement_content104}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content104_list1}}</li>
            <li>{{contentlist.agreement_content104_list2}}</li>
            <li>{{contentlist.agreement_content104_list3}}</li>
            <li>{{contentlist.agreement_content104_list4}}</li>
          </ul>
          <p>
            <strong>{{contentlist.agreement_content105}}</strong> {{contentlist.agreement_content106}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content107}}</strong> {{contentlist.agreement_content108}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content108_list1}}</li>
            <li>{{contentlist.agreement_content108_list2}}</li>
            <li>{{contentlist.agreement_content108_list3}}</li>
            <li>{{contentlist.agreement_content108_list4}}</li>
            <li>{{contentlist.agreement_content108_list5}}</li>
            <li>{{contentlist.agreement_content108_list6}}</li>
            <li>{{contentlist.agreement_content108_list7}}</li>
          </ul>
          <p>
            <strong>{{contentlist.agreement_content109}}</strong> {{contentlist.agreement_content110}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content111}}</strong> {{contentlist.agreement_content112}}
          </p>
          <h5>{{contentlist.agreement_content113}}</h5>
          <p>
            <strong>{{contentlist.agreement_content114}}</strong> {{contentlist.agreement_content115}} 
          </p>
          <p>
            <strong>{{contentlist.agreement_content116}}</strong> {{contentlist.agreement_content117}} 
          </p>
          <h5>{{contentlist.agreement_content118}}</h5>
          <p>
            <strong>{{contentlist.agreement_content119}}</strong> {{contentlist.agreement_content120}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content121}}</strong> {{contentlist.agreement_content122}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content123}}</strong> {{contentlist.agreement_content124}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content125}}</strong> {{contentlist.agreement_content126}} 
          </p>
          <p>{{contentlist.agreement_content127}}</p>
          <ul>
            <li>{{contentlist.agreement_content127_list1}}</li>
            <li>{{contentlist.agreement_content127_list2}}</li>
            <li>{{contentlist.agreement_content127_list3}}</li>
            <li>{{contentlist.agreement_content127_list4}}</li>
            <li>{{contentlist.agreement_content127_list5}}</li>
          </ul>
          <p>{{contentlist.agreement_content128}}</p>
          <ul>
            <li>{{contentlist.agreement_content128_list1}}</li>
            <li>{{contentlist.agreement_content128_list2}}</li>
          </ul>
          <p>
            <strong>{{contentlist.agreement_content129}}</strong> {{contentlist.agreement_content130}}
          </p>
          <h5>{{contentlist.agreement_content131}}</h5>
          <p>{{contentlist.agreement_content132}}</p>
          <ul>
            <li>{{contentlist.agreement_content133}} <a href="mailto:support@capitalwallet.com" class="secondarycolor">{{contentlist.support_email_content}}</a> {{contentlist.agreement_content135}} </li>
            <li>{{contentlist.agreement_content136}}</li>
            <li>{{contentlist.agreement_content137}}</li>
          </ul>
          <h5>{{contentlist.agreement_content138}}</h5>
          <p>
            <strong>{{contentlist.agreement_content139}}</strong> {{contentlist.agreement_content140}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content141}}</strong> {{contentlist.agreement_content142}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content143}}</strong> {{contentlist.agreement_content144}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content145}}</strong> {{contentlist.agreement_content146}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content146_list1}}</li>
            <li>{{contentlist.agreement_content146_list2}}</li>
            <li>{{contentlist.agreement_content146_list3}}</li>
            <li>{{contentlist.agreement_content146_list4}}</li>
            <li>{{contentlist.agreement_content146_list5}}</li>
            <li>{{contentlist.agreement_content146_list6}}</li>
            <li>{{contentlist.agreement_content146_list7}}</li>
            <li>{{contentlist.agreement_content146_list8}}</li>
           </ul>
           <p>
            <strong>{{contentlist.agreement_content147}}</strong> {{contentlist.agreement_content148}}
          </p>
          <h2>{{contentlist.pricing_content}}</h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="primarybg whitecolor">
                <tr>
                  <th>{{contentlist.agreement_content150}}</th>
                  <th>{{contentlist.pricing_content}}</th>
                  <th>{{contentlist.agreement_content152}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{contentlist.agreement_content153}}</th>
                  <td>{{contentlist.agreement_content154}}</td>
                  <td></td>
                </tr>
                <tr>
                  <th>{{contentlist.agreement_content155}}</th>
                  <td>{{contentlist.agreement_content156}}</td>
                  <td></td>
                </tr>
                <tr>
                  <th>{{contentlist.agreement_content157}}</th>
                  <td>{{contentlist.agreement_content158}}</td>
                  <td></td>
                </tr>
                <tr>
                  <th>{{contentlist.agreement_content159}}</th>
                  <td>{{contentlist.agreement_content160}}</td>
                  <td>{{contentlist.agreement_content161}}</td>
                </tr>
                <tr>
                  <th>{{contentlist.agreement_content162}} <br>

                    <!-- Global Start -->
                    <span v-if="entityName == 'global'">{{contentlist.merchant_agreement_global_content163}} <br>
                      {{contentlist.merchant_agreement_global_content163_a}}</span>
                    <!-- Global End -->

                    <!-- APEC Start -->
                    <span v-else>{{contentlist.merchant_agreement_apec_content163}}</span>
                    <!-- APEC End -->
                  
                  </th>
                  <td>{{contentlist.agreement_content164}}</td>
                  <td>{{contentlist.agreement_content165}}</td>
                </tr>
                <tr>
                  <th>{{contentlist.agreement_content166}}</th>
                  <td>{{contentlist.agreement_content167}}</td>
                  <td>{{contentlist.agreement_content168}}</td>
                </tr>
                <tr>
                  <th>
                    <!-- Global Start -->
                    <span v-if="entityName == 'global'">{{contentlist.merchant_agreement_global_content169}} <br>
                      {{contentlist.merchant_agreement_global_content169_a}}</span>
                    <!-- Global End -->

                    <!-- APEC Start -->
                    <span v-else>{{contentlist.merchant_agreement_apec_content169}}</span>
                    <!-- APEC End -->
                  </th>
                  <td>{{contentlist.agreement_content170}}</td>
                  <td>{{contentlist.agreement_content171}}</td>
                </tr>
                <tr>
                  <th>{{contentlist.agreement_content172}}</th>
                  <td>{{contentlist.agreement_content173}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2>{{contentlist.agreement_content174}}</h2>
          <h5>{{contentlist.agreement_content175}}</h5>
          <p>
            <strong>{{contentlist.agreement_content176}}</strong> {{contentlist.agreement_content177}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content178}}</strong> {{contentlist.agreement_content179}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content180}}</strong> {{contentlist.agreement_content181}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content182}}</strong> {{contentlist.agreement_content183}}
          </p>
          <h5>{{contentlist.agreement_content184}}</h5>
          <p>
            <strong>{{contentlist.agreement_content185}}</strong> {{contentlist.agreement_content186}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content186_list1}}</li>
            <li>{{contentlist.agreement_content186_list2}}</li>
            <li>{{contentlist.agreement_content186_list3}}</li>
            <li>{{contentlist.agreement_content186_list4}}</li>
            <li>{{contentlist.agreement_content186_list5}}</li>
            <li>{{contentlist.agreement_content186_list6}}</li>
            
           </ul>
           <p>
            <strong>{{contentlist.agreement_content187}}</strong> {{contentlist.agreement_content188}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content189}}</strong> {{contentlist.agreement_content190}}
          </p>
          <h5>{{contentlist.agreement_content191}}</h5>
          <p>
            {{contentlist.agreement_content192}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content192_list1}}</li>
            <li>{{contentlist.agreement_content192_list2}}</li>
            <li>{{contentlist.agreement_content192_list3}}</li>
            <li>{{contentlist.agreement_content192_list4}}</li>
            <li>{{contentlist.agreement_content192_list5}}</li>
            
           </ul>
           <h5>{{contentlist.agreement_content193}}</h5>
           <p>
            <strong>{{contentlist.agreement_content194}}</strong> {{contentlist.agreement_content195}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content195_list1}}</li>
            <li>{{contentlist.agreement_content195_list2}}</li>
            <li>{{contentlist.agreement_content195_list3}}</li>
            <li>{{contentlist.agreement_content195_list4}}</li>
            <li>{{contentlist.agreement_content195_list5}}</li>
            
           </ul>
           <p>
            <strong>{{contentlist.agreement_content196}}</strong> {{contentlist.agreement_content197}}
          </p>
          <p>
            {{contentlist.agreement_content198}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content199}}</strong> {{contentlist.agreement_content200}}
          </p>
          <h5>{{contentlist.agreement_content201}}</h5>
          <p>
            <strong>{{contentlist.agreement_content202}}</strong> {{contentlist.agreement_content203}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content204}}</strong> {{contentlist.agreement_content205}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content206}}</strong> {{contentlist.agreement_content207}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content208}}</strong> {{contentlist.agreement_content209}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content210}}</strong> {{contentlist.agreement_content211}}
          </p>
          <h5>{{contentlist.agreement_content212}}</h5>
          <p>
            <strong>{{contentlist.agreement_content213}}</strong> {{contentlist.agreement_content214}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content215}}</strong> {{contentlist.agreement_content216}}
          </p>
          <h5>{{contentlist.agreement_content217}}</h5>
          <p>
            <strong>{{contentlist.agreement_content218}}</strong> {{contentlist.agreement_content219}}
          </p>
          <ul>
            <li>{{contentlist.agreement_content219_list1}}</li>
            <li>{{contentlist.agreement_content219_list2}}</li>
            <li>{{contentlist.agreement_content219_list3}}</li>
            <li>{{contentlist.agreement_content219_list4}}</li>
            <li>{{contentlist.agreement_content219_list5}}</li>
            <li>{{contentlist.agreement_content219_list6}}</li>
           </ul>
           <p>
            <strong>{{contentlist.agreement_content220}}</strong> {{contentlist.agreement_content221}}
          </p>
          <h5>{{contentlist.agreement_content222}}</h5>
          <p>
            <strong>{{contentlist.agreement_content223}}</strong> {{contentlist.agreement_content224}}
          </p>
          <p>
            <strong>{{contentlist.agreement_content225}}</strong> {{contentlist.agreement_content226}}
          </p>
          <h5>{{contentlist.agreement_content227}}</h5>
          <p>
            {{contentlist.agreement_content228}}
          </p>
          <h5>{{contentlist.agreement_content229}}</h5>
          <p>
            {{contentlist.agreement_content230}} <a href="mailto:contact@capitalwallet.com" class="secondarycolor">{{contentlist.contact_email_content}}</a>
          </p>
          


           
          
          

          

        </div>
      </section>
    </div>
    <!-- <Footer /> -->
    <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
        <div class="container">
           <div class="row justify-content-center">
              <div class="col-12 col-xl-10 text-center">
                 <div class="criptoIcon">
                    <span class="left-tp tow"><img src="/assets/images/left-mail.webp" alt="Icon" title="left mail" width="231" height="168" class="img-fluid"></span>
                    <span class="left-tp three"><img src="/assets/images/right-mail.webp" alt="Icon" title="right mail" width="87" height="83" class="img-fluid"><img src="/assets/images/left-mail-1.webp" alt="Icon" title="right mail" width="69" height="66" class="img-fluid onSmall"></span>
                 </div>
                 <div class="content position-relative">
                    <h2 class="whitecolor">{{contentlist.cta_one_content1}}</h2>
                    <p class="whitecolor regular mb-0">{{contentlist.cta_two_content2}}</p>
                    <div class="position-relative mt-5 newsletterBox">
                       <input type="text" placeholder="Email Address" class="form-control max-100 rounded-pill border-0" name="">
                       <a href="javascript:void(0)" class="newsletterBtn l-1 position-absolute rounded-pill">{{contentlist.cta_one_content3}}</a>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
  </div>
</template>
<script>
import {
   commonAllmixins
 } from '@/plugins/commonAll' //anamica
  export default ({
   mixins: [commonAllmixins], //anamica
    data() {
      return {
        tab: 1,
      };
    },
  });
</script>